import * as Sentry from "@sentry/react";

const env = import.meta.env.MODE;
const tracesSampleRate = env === "production" ? 0.2 : 1.0;
const dsn = document.querySelector('meta[name="sentry-dsn"]')?.getAttribute("content");
const isEnabled = import.meta.env.PROD;

Sentry.init({
  enabled: isEnabled,
  dsn: dsn,
  tracesSampleRate: tracesSampleRate,
  integrations: [Sentry.browserTracingIntegration()],
  environment: env,
});
